import React from "react";
//import SiteSelectionDropdown from "./SiteSelectionDropdown";
import LogoutButton from "./LogoutButton";
import { Link } from "react-router-dom";
import { MagnifyingGlass, ClockCounterClockwise, UserList, Users, User, Notepad, Buildings } from "phosphor-react";
import UseAuthStatus from '../Hooks/UseAuthStatus'; // Adjust path as necessary

/*
const handleSiteChange = (siteId) => {
  console.log("Selected site ID:", siteId);
  // Update state or make API calls based on selected site
};
*/

const Sidebar = () => {
  const { userEmail, userRole } = UseAuthStatus();
  return (
    <aside className="menu p-6">
      <Link to={"/search"}>
        <img src="/merlin-logo.png" style={{"height": "100px", "width": "auto"}} alt="Merlin Entertainments" />
      </Link>
      {
        /*
          <p className="menu-label mt-6 is-size-6">Site selection:</p>
          <SiteSelectionDropdown onSiteChange={handleSiteChange} />
        */
      }
      <p className="menu-label mt-6 is-size-6">General</p>
      <ul className="menu-list">
        <li>
          <a href="/search" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <MagnifyingGlass weight="duotone" className="has-text-info" /> 
              </span>
              <span>New search</span>
            </span>
          </a>
        </li>
        <li>
          <a href="/history" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <ClockCounterClockwise weight="duotone" className="has-text-info" />
              </span>
              <span>Search history</span>
            </span>
          </a>
        </li>
        <li>
          <a href="/activity" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <UserList weight="duotone" className="has-text-info"/> 
              </span>
              <span>My activity</span>
            </span>
          </a>
        </li>
      </ul>
      { userRole === 'admin' && (
        <>
          <p className="menu-label mt-6 is-size-6">Administration</p>
          <ul className="menu-list">
            <li>
              <a href="/admin/users" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Users weight="regular" className='has-text-info'/>
                  </span>
                  <span>Users</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/sites" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Buildings weight="regular" className='has-text-info' />
                  </span>
                  <span>Sites</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/logs" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Notepad weight="duotone" className='has-text-info' /> 
                  </span>
                  <span>Logs</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/cron-executions" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Notepad weight="duotone" className='has-text-info' /> 
                  </span>
                  <span>Crons</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/jms-requests-stats" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Notepad weight="duotone" className='has-text-info' /> 
                  </span>
                  <span>JMS requests</span>
                </span>
              </a>
            </li>
          </ul>
        </>
      )
      }
      <p className="menu-label mt-6 is-size-6">{userEmail}</p>
      <ul className="menu-list">
        <li>
          <LogoutButton />
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
