import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const JmsRequestStatsPage = () => {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/jms-requests/stats`,
          {
            withCredentials: true,
          }
        );
        setStats(response.data);
      } catch (err) {
        console.error("Failed to fetch JMS request stats:", err);
        setError("Failed to load JMS request stats. Please try again later.");
        toast.error("Failed to load JMS request stats.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  const timeRangeLabels = {
    last7days: "7d",
    last3days: "3d",
    last24hours: "24h",
    last12hours: "12h",
    last3hours: "3h",
    last1hour: "1h",
  };

  // Helper function to render the total statistics per time period
  const renderTotalStatsRow = (rangeKey, rangeStats) => {
    const totalStats = rangeStats.totalStats;

    return (
      <tr key={rangeKey}>
        <td>{timeRangeLabels[rangeKey]}</td>
        <td>{totalStats.totalRequests || 0}</td>
        <td>{totalStats.minExecutionTime || 0} ms</td>
        <td>{totalStats.maxExecutionTime || 0} ms</td>
        <td>{(totalStats.avgExecutionTime || 0).toFixed(0)} ms</td>
      </tr>
    );
  };

  // Helper function to build the aggregated string for each stat by endpoint
  const buildStatString = (rangeStats, statKey) => {
    return Object.keys(rangeStats)
      .map((rangeKey) => {
        const rangeLabel = timeRangeLabels[rangeKey];
        const statValue = rangeStats[rangeKey][statKey].toFixed(0);
        return `${rangeLabel}: ${statValue}`;
      })
      .join("<br />");
  };

  // Aggregating the endpoint stats
  const aggregateEndpointStats = Object.keys(stats).reduce((acc, rangeKey) => {
    const rangeStats = stats[rangeKey];
    rangeStats.endpointStats.forEach((endpointStat) => {
      if (!acc[endpointStat.endpoint]) {
        acc[endpointStat.endpoint] = {};
      }
      acc[endpointStat.endpoint][rangeKey] = endpointStat;
    });
    return acc;
  }, {});

  // Rendering rows for the aggregated endpoint stats
  const renderEndpointStatsRows = (aggregatedStats) => {
    return Object.keys(aggregatedStats).map((endpoint) => {
      const rangeStats = aggregatedStats[endpoint];
      const formattedEndpoint = endpoint
        .replace("https://", "")
        .replace("http://", "")
        .replace(/\//g, "/<br>");
      return (
        <tr key={endpoint}>
          <td
            style={{ maxWidth: "200px", wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{ __html: formattedEndpoint }}
          />
          <td
            dangerouslySetInnerHTML={{
              __html: buildStatString(rangeStats, "totalRequests"),
            }}
          />
          <td
            dangerouslySetInnerHTML={{
              __html: buildStatString(rangeStats, "minExecutionTime"),
            }}
          />
          <td
            dangerouslySetInnerHTML={{
              __html: buildStatString(rangeStats, "maxExecutionTime"),
            }}
          />
          <td
            dangerouslySetInnerHTML={{
              __html: buildStatString(rangeStats, "avgExecutionTime"),
            }}
          />
        </tr>
      );
    });
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <h3 className="title is-4">JMS Request Statistics</h3>
          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {/* Total Stats */}
              <div className="box">
                <h4 className="title is-5">Total Request Statistics</h4>
                <table className="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>Time Period</th>
                      <th>Total Requests</th>
                      <th>Min time (ms)</th>
                      <th>Max time (ms)</th>
                      <th>Avg time (ms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(stats).map((rangeKey) =>
                      renderTotalStatsRow(rangeKey, stats[rangeKey])
                    )}
                  </tbody>
                </table>
              </div>
              <div className="box">
                {/* Endpoint Stats */}
                <h4 className="title is-5">Request Statistics by Endpoint</h4>
                <table className="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>Endpoint</th>
                      <th>Total Requests</th>
                      <th>Min time (ms)</th>
                      <th>Max time (ms)</th>
                      <th>Avg time (ms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderEndpointStatsRows(aggregateEndpointStats)}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default JmsRequestStatsPage;
