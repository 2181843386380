import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const CronExecutionsPage = () => {
  const [cronExecutions, setCronExecutions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(25);
  const [search, setSearch] = useState("");
  const [selectedCron, setSelectedCron] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);

  // State for triggering the manual full sync
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    const fetchCronExecutions = async () => {
      setIsLoading(true);
      try {
        const params = {
          page,
          limit,
          search,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/cron-executions`,
          {
            params,
            withCredentials: true,
          }
        );
        setCronExecutions(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        console.error("Failed to fetch cron executions:", err);
        setError("Failed to load cron executions. Please try again later.");
        toast.error("Failed to load cron executions.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCronExecutions();
  }, [page, search, limit]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleViewOutput = (cron) => {
    setSelectedCron(cron);
    setIsModalActive(true);
  };

  const closeModal = () => {
    setIsModalActive(false);
    setSelectedCron(null);
  };

  const highlightErrors = (text) => {
    if (!text) return null;

    const lines = text.split("\n");
    const result = [];
    let isErrorBlock = false;
    let errorBlockLines = [];
    let braceStack = [];
    let keyCounter = 0;

    lines.forEach((line) => {
      if (!isErrorBlock) {
        // Check if the line starts with "Error"
        if (line.startsWith("Error")) {
          isErrorBlock = true;
          errorBlockLines.push(line);

          // Update brace stack
          const openBraces = (line.match(/{/g) || []).length;
          const closeBraces = (line.match(/}/g) || []).length;

          for (let i = 0; i < openBraces; i++) {
            braceStack.push("{");
          }
          for (let i = 0; i < closeBraces; i++) {
            braceStack.pop();
          }

          if (braceStack.length === 0) {
            // No braces to balance, so end of error block
            isErrorBlock = false;
            // Wrap the error block lines
            result.push(
              <span
                key={keyCounter++}
                style={{
                  backgroundColor: "#ffe0e0",
                  color: "#f3000",
                  padding: "0 2px",
                  display: "block",
                }}
              >
                {errorBlockLines.join("\n")}
              </span>
            );
            errorBlockLines = [];
          }
        } else {
          // Line does not start with "Error", include as is
          result.push(<span key={keyCounter++}>{line + "\n"}</span>);
        }
      } else {
        // We are inside an error block
        errorBlockLines.push(line);

        // Update brace stack
        const openBraces = (line.match(/{/g) || []).length;
        const closeBraces = (line.match(/}/g) || []).length;

        for (let i = 0; i < openBraces; i++) {
          braceStack.push("{");
        }
        for (let i = 0; i < closeBraces; i++) {
          if (braceStack.length > 0) {
            braceStack.pop();
          } else {
            // Unbalanced closing brace
            console.warn("Unbalanced closing brace detected in cron output");
          }
        }

        if (braceStack.length === 0) {
          // Balanced braces, end of error block
          isErrorBlock = false;
          // Wrap the error block lines
          result.push(
            <span
              key={keyCounter++}
              style={{
                backgroundColor: "#ffe0e0",
                color: "#f3000",
                padding: "0 2px",
                display: "block",
              }}
            >
              {errorBlockLines.join("\n")}
            </span>
          );
          errorBlockLines = [];
        }
      }
    });

    // If there's any remaining lines in errorBlockLines, wrap them as well
    if (errorBlockLines.length > 0) {
      result.push(
        <span
          key={keyCounter++}
          style={{
            backgroundColor: "#ffe0e0",
            color: "#f3000",
            padding: "0 2px",
            display: "block",
          }}
        >
          {errorBlockLines.join("\n")}
        </span>
      );
    }

    return result;
  };

  const triggerFullSync = async () => {
    try {
      setIsSyncing(true);
      toast.info("Starting full sync...");
      const response = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/cron/fullsync`,
        {},
        { withCredentials: true }
      );
      toast.success(
        "Full sync started successfully. Check cron executions for progress."
      );
    } catch (err) {
      console.error("Failed to trigger full sync:", err);
      toast.error("Failed to start full sync. Please check the server logs.");
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <h3 className="title is-4">Cron Executions</h3>

          {/* Add a button to trigger the full sync */}
          <div className="field">
            <button
              className="button is-primary"
              onClick={triggerFullSync}
              disabled={isSyncing}
            >
              {isSyncing ? "Starting full sync..." : "Trigger Full Sync"}
            </button>
          </div>

          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <table className="table is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Duration (s)</th>
                    <th>Output</th>
                  </tr>
                </thead>
                <tbody>
                  {cronExecutions.map((cron) => (
                    <tr key={cron._id}>
                      <td>{new Date(cron.startTime).toLocaleString()}</td>
                      <td>
                        {cron.endTime
                          ? new Date(cron.endTime).toLocaleString()
                          : "N/A"}
                      </td>
                      <td>
                        {cron.duration ? cron.duration.toFixed(2) : "N/A"}
                      </td>
                      <td>
                        <button
                          className="button is-small is-info"
                          onClick={() => handleViewOutput(cron)}
                        >
                          View Output
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="pagination"
                role="navigation"
                aria-label="pagination"
              >
                <button
                  className="pagination-previous"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page <= 1}
                >
                  Previous
                </button>
                <button
                  className="pagination-next"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= totalPages}
                >
                  Next page
                </button>
                <ul className="pagination-list">
                  {/* Show "First" button if not on the first page */}
                  {page > 3 && (
                    <>
                      <li>
                        <button
                          className="pagination-link"
                          onClick={() => handlePageChange(1)}
                        >
                          First
                        </button>
                      </li>
                      <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                      </li>
                    </>
                  )}

                  {/* Calculate page window */}
                  {Array.from({ length: totalPages }, (_, i) => i + 1)
                    .filter((p) => p >= page - 2 && p <= page + 2) // Show pages within 2 steps from current page
                    .map((p) => (
                      <li key={p}>
                        <button
                          className={`pagination-link ${
                            page === p ? "is-current" : ""
                          }`}
                          onClick={() => handlePageChange(p)}
                        >
                          {p}
                        </button>
                      </li>
                    ))}

                  {/* Show "Last" button if not near the last page */}
                  {page < totalPages - 2 && (
                    <>
                      <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                      </li>
                      <li>
                        <button
                          className="pagination-link"
                          onClick={() => handlePageChange(totalPages)}
                        >
                          Last
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </>
          )}

          {selectedCron && (
            <div className={`modal ${isModalActive ? "is-active" : ""}`}>
              <div className="modal-background" onClick={closeModal}></div>
              <div className="modal-card" style={{ width: "80%" }}>
                <header className="modal-card-head">
                  <p className="modal-card-title">Cron Output</p>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={closeModal}
                  ></button>
                </header>
                <section className="modal-card-body">
                  <pre
                    style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                  >
                    {highlightErrors(selectedCron.output)}
                  </pre>
                </section>
                <footer className="modal-card-foot">
                  <button className="button" onClick={closeModal}>
                    Close
                  </button>
                </footer>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CronExecutionsPage;
